.customInput { 
    position:relative; 
    display: inline-block; 
    width: 100%;
}
.customInput span { 
    position: absolute; 
    top:10px; 
    right: 10px; 
    line-height:100%; 
    vertical-align: middle; 
    color: grey;
    font-size:var(--font-size-l);
}
.num_custom { 
    width: 100%;
    text-align: right; 
    padding-right: 15px; 
}