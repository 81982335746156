.button-custom {
    -webkit-text-size-adjust: 100%;
    visibility: visible;
    box-sizing: inherit;
    margin: 0;
    vertical-align: middle;
    margin-left: 0;
    font-family: var(--primary-font-family);
    cursor: pointer;
    width: auto;
    border-radius: var(--element-radius);
    box-shadow: none;
    display: inline-block;
    font-size: var(--base-font-size);
    font-weight: 700;
    line-height: 21px;
    outline: 0;
    padding: 4px 12px;
    text-shadow: none;
    word-spacing: 2px;
    background: var(--main-action-hover-bg);
    border: 1px solid var(--main-action-hover-bg);
    color: var(--main-action-hover-color);
}